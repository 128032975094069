import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../shared/services/auth.service';
import {HotelService} from '../shared/services/hotel.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;

  hotelIdTest: string;

  constructor(@Inject(AuthService) private authService: AuthService,
              @Inject(HotelService) private hotelService: HotelService) {
    this.loginForm = new FormGroup({
      hotelId: new FormControl(null, [Validators.required])
    });
  }

  submit() {
    if (this.loginForm.valid) {
      // this.authService.login(this.loginForm.value);
      this.hotelService.findHotel(this.loginForm.value.hotelId);
    }
  }

  createHotel() {
    this.hotelService.createHotel()
      .subscribe(res => {
        this.hotelIdTest = res.hotelId;
      });
  }
}

