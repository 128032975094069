import {Inject, Injectable} from '@angular/core';
import {ApiBaseService} from './api-base.service';
import {Dictionary} from '../../models/Dictionary';
import {Constants} from '../utils/Constants';
import {forkJoin, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {
  roomFacilities: Dictionary[] = [];
  hotelFacilities: Dictionary[] = [];
  hotelTypes: Dictionary[] = [];
  bedTypes: Dictionary[] = [];
  childBedTypes: Dictionary[] = [];
  ready = new Subject<boolean>();
  constructor(@Inject(ApiBaseService) private apiService: ApiBaseService) {}

  init() {
    forkJoin(
      this.load(Constants.API_GET_HOTEL_FACILITIES),
      this.load(Constants.API_GET_BED_TYPES),
      this.load(Constants.API_GET_ROOM_FACILITIES),
      this.load(Constants.API_GET_HOTEL_TYPES),
      this.load(Constants.API_GET_HOTEL_CHILD_BED_TYPES)
    ).subscribe((res: any) => {
        [this.hotelFacilities, this.bedTypes, this.roomFacilities, this.hotelTypes, this.childBedTypes] = res;
        this.ready.next(true);
      });
  }

  async load(api: string) {
    return new Promise(Resolve => {
      this.apiService.get(api).subscribe( res => {
        Resolve(res);
      });
    });
  }
}
