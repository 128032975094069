import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiBaseService} from './api-base.service';
import {Constants} from '../utils/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuthorized = new BehaviorSubject<string>(null);

  constructor(@Inject(ApiBaseService) private apiService: ApiBaseService) { }

  login(data: any) {
    localStorage.setItem('token', '...');
    this.apiService.post(data, Constants.API_POST_LOGIN)
      .subscribe(res => {
        localStorage.setItem('token', '...');
      });
  }
}
