<app-header [isAllHotelsPage]="true"></app-header>
<div class="page-wrapper">
  <div class="create-wrapper" (click)="onShowAddModal()">
    <div class="create-btn">
      +
    </div>
    <span>Додати новий об'єкт</span>
  </div>
  <div *ngIf="createLoading; else mainContent">
    <div class="text-center">
      <div class="empty-text">Створення нового об'єкту, будь ласка зачекайте...</div>
    </div>
  </div>
  <div *ngIf="userHotels.length === 0 && loaded" class="text-center">
    <div class="empty-text">У Вас немає зареєстрованих об'єктів</div>
  </div>
  <div *ngIf="!loaded" class="text-center">
    <div class="empty-text">Завантаження доступних об'єктів...</div>
  </div>
  <ng-template #mainContent>
    <div class="hotels-wrapper" *ngIf="userHotels.length > 0 && loaded">
      <div class="hotel" *ngFor="let hotel of userHotels; index as i">
        <div class="hotel__info">
          <span class="title">{{hotel.hotelInfoTranslations.length ? hotel.hotelInfoTranslations[0]['hotelName'] ?? '': ''}}</span>
          <div class="buttons">
            <button class="btn btn-danger remove-hotel" (click)="onShowDeleteModal(hotel)">Видалити об'єкт</button>
            <button class="btn btn-secondary" (click)="toHotelInfo(hotel)">Для повного керування потрібно перейти на сторінку об'єкту ></button>
          </div>
          <span class="warning-message" *ngIf="rooms[i] && rooms[i].length">Для швидкого закриття поставте 0</span>
          <ng-template #roomLoading>
            <div class="room-loading">
              <span class="width-100 text-center">Завантажуються дані про ціни...</span>
            </div>
          </ng-template>
        </div>
        <div class="table-wrapper" *ngIf="rooms[i]; else roomLoading">
          <app-small-review-table
            [rooms] = "rooms[i]"
            [hotel] = "hotel"
            [period] = "period">
          </app-small-review-table>
        </div>
      </div>
    </div>
  </ng-template>
</div>
