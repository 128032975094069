<div id="loginForm">
  <form [formGroup]="loginForm" (ngSubmit)="submit()">
    <label for="hotelId">Введите id отеля</label> <input id="hotelId" formControlName="hotelId"> <button type="submit">Войти</button>
  </form>
  <div *ngIf="!hotelIdTest; else hotelTest">Или создать тестовый <button (click)="createHotel()">Создать</button></div>
  <ng-template #hotelTest>
    Ваш id отеля {{hotelIdTest}}
  </ng-template>
</div>

