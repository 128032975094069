import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from "../../shared/services/dictionary.service";
import { HotelService } from "../../shared/services/hotel.service";
import { take } from "rxjs/operators";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  showReady = false;
  constructor(@Inject(DictionaryService) private dictionaryService: DictionaryService,
    @Inject(HotelService) private hotelService: HotelService,
    private activatedRoute: ActivatedRoute) {
    dictionaryService.init();
    hotelService.hotelCache();
    dictionaryService.ready.pipe(take(1))
      .subscribe(() => {
        this.showReady = true;
      });


  }

  ngOnInit(): void {
    //console.log("MainLayoutComponent id", this.activatedRoute.snapshot.paramMap.get('id'));
  }

}
