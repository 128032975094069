import {Component, HostListener, OnDestroy} from '@angular/core';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import {Subscription} from "rxjs";
import {Constants} from "../shared/utils/Constants";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {
  menuOpen = false;
  isMobile = false;
  private readonly routerSubscription: Subscription;

  constructor(private router: Router) {
    this.checkIfMobile();
    this.routerSubscription = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && this.isMobile) {
        this.menuOpen = false;
      }
    });
  }

  toggleMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  @HostListener('window:resize')
  checkIfMobile(): void {
    this.isMobile = window.innerWidth <= Constants.mobileResolution;
    this.menuOpen = !this.isMobile;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
