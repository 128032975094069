import { Injectable } from '@angular/core';
import {Observable, of, of as observableOf} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  deepExtend,
  NbAuthResult,
  NbAuthStrategyClass,
  NbPasswordAuthStrategy,
  NbPasswordAuthStrategyOptions,
} from '@nebular/auth';

@Injectable()
export class CustomNbPasswordAuthStrategy extends NbPasswordAuthStrategy {
  static override setup(
    options: NbPasswordAuthStrategyOptions
  ): [NbAuthStrategyClass, NbPasswordAuthStrategyOptions] {
    return [CustomNbPasswordAuthStrategy, options];
  }

  override refreshToken(data?: any): Observable<NbAuthResult> {
    const module = 'refreshToken';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
      data = localStorage.getItem('refresh_token');

      return this.http
        .request(method, url, {
          body: {refresh_token: data},
          observe: 'response',
          headers: this.getHeaders(),
        })
        .pipe(
          map(res => {
            if (this.getOption(`${module}.alwaysFail`)) {
              throw this.createFailResponse(data);
            }

            return res;
          }),
          map(res => {
            return new NbAuthResult(
              true,
              res,
              this.getOption(`${module}.redirect.success`),
              [],
              this.getOption('messages.getter')(module, res, this.options),
              this.createToken(
                this.getOption('token.getter')(module, res, this.options),
                requireValidToken
              )
            );
          }),
          catchError(res => {
            return this.handleResponseError(res, module);
          })
        );

  }

  override logout(): Observable<NbAuthResult> {
    const module = 'logout';
    return of(null);
  }

}
export function getCustomDeepFromObject(
  object = {},
  name: string,
  defaultValue?: any
) {
  const keys = name.split('.');
  let level = deepExtend({}, object || {});

  localStorage.setItem('refresh_token', level['refresh_token']);
  return level['token'];
}
