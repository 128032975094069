import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../utils/Constants';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  hotelApi: string;
  roomApi: string;
  reservationApi: string;

  constructor(private http: HttpClient) { }

  formAPi(hotelId: string) {
    this.hotelApi = Constants.API_HOTELS + '/' + hotelId;
    this.roomApi = this.hotelApi + '/rooms';
    this.reservationApi = this.hotelApi + '/reservations';
  }

  getWithParams(url: string, params: any): Observable<any> {
    return this.http.get<any>(url, params);
  }

  get(url: any): Observable<any> {
    return this.http.get(url, this.httpOptions);
  }
  post(body: any, url: string): Observable<any> {
    return this.http.post(url, body, this.httpOptions);
  }
  patch(body: any, url: string): Observable<any> {
    return this.http.patch(url, body, this.httpOptions);
  }
  put(body: any, url: string): Observable<any> {
    return this.http.put(url, body, this.httpOptions);
  }
  delete(url: string): Observable<any> {
    return this.http.delete(url, this.httpOptions);
  }
  postFile(body: any, url: string): Observable<any> {
    const options = { headers: this.httpOptions.headers.delete('Content-type') };
    return this.http.post(url, body, options);
  }
}
