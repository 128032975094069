import {environment} from '../../../environments/environment';

export class Constants {
  static readonly LANGUAGES = ['uk', 'en'];
  static readonly mobileResolution = 1200;

  private static readonly baseAPI = environment.apiUrl;
  // general
  public static readonly API_POST_LOGIN = Constants.baseAPI + 'login';
  // dictionary
  public static readonly API_GET_HOTEL_FACILITIES = Constants.baseAPI + 'dictionaries/hotelServices';
  public static readonly API_GET_ROOM_FACILITIES = Constants.baseAPI + 'dictionaries/hotelRoomFacility';
  public static readonly API_GET_BED_TYPES = Constants.baseAPI + 'dictionaries/hotelRoomBedType';
  public static readonly API_GET_HOTEL_TYPES = Constants.baseAPI + 'dictionaries/hotelType';
  public static readonly API_GET_HOTEL_CHILD_BED_TYPES = Constants.baseAPI + 'dictionaries/hotelRoomChildBedAccommodation';
  // general
  public static readonly API_HOTELS = Constants.baseAPI + 'hotels';
  public static readonly API_POST_HOTEL_UPDATE = Constants.baseAPI + 'hotel';
  // rooms
  public static readonly API_GET_ROOMS_LIST = Constants.baseAPI + 'room/getAll';
  public static readonly API_GET_ROOM = Constants.baseAPI + 'room/'; // .../room/{{roomId}}
  public static readonly API_POST_ROOM_CREATE = Constants.baseAPI + 'room/create';
  public static readonly API_POST_ROOM_UPDATE = Constants.baseAPI + 'room/update';
  public static readonly API_GET_ROOM_DELETE = Constants.baseAPI + 'room/delete/'; // .../room/delete/{{roomId}}
  // tariffs
  public static readonly API_GET_TARIFFS_LIST = Constants.baseAPI + 'tariff/getALL';
  public static readonly API_GET_TARIFF = Constants.baseAPI + 'tariff/'; // .../tariff/{{tariffId}}
  public static readonly API_POST_TARIFF_CREATE = Constants.baseAPI + 'tariff/create/'; // .../tariff/create/{{roomId}}
  public static readonly API_POST_TARIFF_UPDATE = Constants.baseAPI + 'tariff/update';
  public static readonly API_GET_TARIFF_DELETE = Constants.baseAPI + 'tariff/delete/'; // .../tariff/delete/{{tariffId}}
  // rates
  public static readonly API_POST_RATE_LIST = Constants.baseAPI + 'rate/getALL/'; // .../rate/getALL/{{roomId}}
  public static readonly API_POST_RATE_UPDATE = Constants.baseAPI + 'rate/update';
  public static readonly API_POST_RATE_DAY_UPDATE = Constants.baseAPI + 'rate/day/update';
  // photo
  public static readonly API_GET_PHOTOS = Constants.baseAPI + 'photo/getAll';
  public static readonly API_POST_LOAD_PHOTO = Constants.baseAPI + 'photo/load';
  public static readonly API_GET_DELETE_PHOTO = Constants.baseAPI + 'photo/delete/'; // .../photo/delete/{{photoId}}
  public static readonly API_GET_ROOM_PHOTOS = Constants.baseAPI + 'photo/room/getAll/'; // .../photo/room/getAll/{{roomId}}
  public static readonly API_GET_ROOM_PHOTO_RELATE = Constants.baseAPI + 'photo/room/relate'; // .../photo/room/relate/{{roomId}}/{{photoId}}
  public static readonly API_GET_ROOM_PHOTO_DELETE = Constants.baseAPI + 'photo/room/delete'; // .../photo/room/delete/{{roomId}}/{{photoId}}
  // discount
  public static readonly API_POST_DISCOUNT_UPDATE = Constants.baseAPI + 'discount/update';
  public static readonly API_POST_DISCOUNT_CREATE = Constants.baseAPI + 'discount/create/'; // .../discount/create/{{tariffId}}
  public static readonly API_GET_DISCOUNT_DELETE = Constants.baseAPI + 'discount/delete/'; // .../discount/delete/{{discountId}}
  // booking
  public static readonly API_POST_BOOKINGS = Constants.baseAPI + 'booking/getAll';
  public static readonly API_GET_BOOKING = Constants.baseAPI + 'booking/'; // .../booking/{{bookingId}}
  public static readonly API_POST_BOOKING_UPDATE = Constants.baseAPI + 'booking/update';
  public static readonly API_POST_BOOKING_STATUS = Constants.baseAPI + 'booking/status/'; // .../booking/status/{{bookingId}}
}
