import { Component, Inject, OnInit } from '@angular/core';
import { DictionaryService } from './shared/services/dictionary.service';
import { HotelService } from './shared/services/hotel.service';
import { take } from 'rxjs/operators';
import { Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'extranet';
  showReady = false;
  constructor(@Inject(DictionaryService) private dictionaryService: DictionaryService,
    @Inject(HotelService) private hotelService: HotelService,
    private router: Router
  ) {
    dictionaryService.init();
    hotelService.hotelCache();
    dictionaryService.ready.pipe(take(1))
      .subscribe(() => {
        this.showReady = true;
      });

    //if (!localStorage.getItem('hotel'))
      //localStorage.setItem('hotel', '');
    //if (!localStorage.getItem('refresh_token'))
      //localStorage.setItem('refresh_token', '16f1210ac42794de2ce343b4cd674acfa999f12c7944e8d7ec27dfdf68b5f3f6180b0e63b6acb2fedfee8871dc4ee683eda9e051786dbbbb668297c79cd44411')
    //if (!localStorage.getItem('auth_app_token'))
      //localStorage.setItem('auth_app_token', '{"name":"nb:auth:jwt:token","ownerStrategyName":"email","createdAt":1676548179000,"value":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NzY2Mjc5NDgsImV4cCI6MTY3NjcxNDM0OCwicm9sZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9IT1RFTElFUiJdLCJlbWFpbCI6ImJvbmRhcmNodWtAdWtyLm5ldCIsImhvdGVsSWQiOjh9.T2GyiZ93S_fpmy4EA5b56WswmkGm1Jnviy1r_qnyBp8r0u_n2gp7NMfynPDkGDY_MvWQ56VMlcU76nFfGcMNXoFFZDVNfXet__V1sRrUrbiOdh6LR7oEuCiqoCJRVQ7sCfzEcQhs3qtDJswxSJYe5oC2JJJAHJ1S9Pk62fckS8W_iv9mH9dWY2g4kzhJqQRK0usat6aZxXRNzlLZG-KC6MjM5nHD7rayR5A0pOzI3NagKYL2Ld2IxmLcqrf-gd5n5YvVxvVxFzkHPQLmFs97yzzVThYiUt9mt_jtwQfSlV6VyoBY5K6USb9JUripUkepdZvdbhBFx0Eh6uvLFQHaAg"}')
    //console.log('setItem done');
  }
  ngOnInit(): void {
    /*    this.router.events.subscribe((event) => {
          document.body.classList.remove('nb-theme-default');
        });*/


  }
}
