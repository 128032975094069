<nb-layout>
  <nb-layout-column>
    <nb-card>
      <nb-card-body class="text-center">
        <img src="/assets/images/Yavdoma_com_logo_blue.png" alt="" height="20px" class="my-3">
        <h2>Навчальні відео для користування Екстранетом</h2>
        <p></p>
        <div class="container-video m-auto">
          <!-- VIDEO 1 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/1_registration.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Реєстрація об'єкта проживання на Yavdoma.com</p>
              <p class="description">Заповнення форми реєстрації. Верифікація користувача. Контракт з Yavdoma.com. 
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 2 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/2_general_info.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Загальна інфо про ваш об'єкт проживання</p>
              <p class="description">Адреса об'єкта, опис та контактні дані. Зручності в об'єкті проживання. Умови проживання дітей, податки, додаткові ліжка.  
                 <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 3 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/3_create_room.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Створення номерів</p>
              <p class="description">Створення категорій номерів. Як створити номер для будинку/віли/шале? Кількість дорослих та дітей у номері. Зручності у номері. 
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 4 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/4_add_photo.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Додаємо фото та відео</p>
              <p class="description">Правила додавання. Технічні вимоги до фото/відео. Аватарка об'єкта та номера.  
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 5 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/5_tariffs_and_prices.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Встановлення цін та створення тарифів</p>
              <p class="description">Створення та редагування тарифів. Робота з таблицею ОГЛЯД ЦІН. Встановлення цін. Відкриття та закриття продажів номерів.  
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 6 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/6_discounts.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Знижки</p>
              <p class="description">Встановлення, зміна та видалення знижок. 
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 7 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/7_reservations.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Бронювання</p>
              <p class="description">Список бронювань об'єкта проживання. Відміна бронювання. Виставлення НЕЗАЇЗДу. 
                <br>
              </p>
            </div>
          </div>
          <!-- VIDEO 8 -->
          <div class="video-block d-flex border-top pt-1">
            <div>
              <video src="/assets/educational-video/8_children_tariff.mp4" controls></video>
            </div>
            <div class="ms-4 text-start">
              <p class="title">Тариф: Проживання з дітьми.</p>
              <p class="description">Як створити умови проживання дітей? Як призначити оплату за дітей?
                <br>
              </p>
            </div>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </nb-layout-column>

</nb-layout>
