import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDialogModule, NbInputModule, NbLayoutModule, NbRadioModule, NbThemeModule, NbTooltipModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    NbCardModule,
    NbButtonModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbDialogModule,
    NbAlertModule,
    NbInputModule,
    NbCheckboxModule,
    NbTooltipModule,
    NbRadioModule,
  ]
})
export class NbShareModule { }
